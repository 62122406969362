<template>
  <div
    ref="wrapper"
    class="table-wrapper"
  >
    <b-table
      :id="`dt_${dtKey}`"
      ref="datatable"
      class="datatable"
      striped
      hover
      small
      :sticky-header="stickyHeight"
      :items="items && items.data"
      :fields="tableFields"
      :tbody-tr-class="tbodyTrClass"
      @update="onUpdate"
    >
      <template #head()="data">
        <div
          :ref="`${data.column}`"
          v-shtml="data.label"
          class="dt-th"
          :class="`${data.column}`"
          @click.stop.prevent="(e) => { onSort(e, data.column) }"
        >
          {{ data.label }}
        </div>
      </template>
      <template #head(batch)="data">
        <slot
          :name="`head(batch)`"
          :data="{ data }"
        >
          {{ data.label }}
        </slot>
      </template>
      <template
        v-for="field in fields"
        #[`cell(${field.key})`]="data"
      >
        <slot
          :name="`cell(${field.key})`"
          :data="{ item: data.item, index: data.index, field: data.field }"
        >
          <span :key="`${field.key}_${data.item.id}`">{{ data.value }}</span>
        </slot>
      </template>
    </b-table>
    <slot name="pagination">
      <div
        v-if="!isMobile"
        :id="`dt_${dtKey}_pager`"
        ref="pager"
        class="pager"
      >
        <b-pagination
          v-if="currentPage"
          v-model="currentPage"
          :total-rows="pager && pager.total"
          :per-page="pager && pager.per_page"
          :aria-controls="`dt_${dtKey}`"
          :disabled="busy"
          @input="onPageChanged"
        />
        <div
          v-if="limit"
          class="d-inline-flex align-items-center text-nowrap"
        >
          <span class="pr-2 pager__page_size_title">{{ $t('eDt_pageSize') }}</span>
          <b-form-select
            v-model="pageSize"
            :options="pageSizes"
            size="sm"
          />
        </div>
        <strong v-if="pager" class="pager__info small">
          {{ $t('eDt_iteratorCounts', {
            from: ((pager.current_page - 1) * pager.per_page) + 1,
            to: (pager.current_page) * pager.per_page,
            total: pager.total
          }) }}
        </strong>
      </div>
    </slot>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { isServer } from '@/mixins/helpers'

export default {
  name: 'DataTable',
  props: {
    repo: {
      type: String,
      default: ''
    },
    parentId: {
      type: [String, Number, null],
      default: () => null
    },
    fields: {
      type: Array,
      default: () => ([])
    },
    filter: {
      type: Array,
      default: () => ([])
    },
    order: {
      type: Object,
      default: () => ({})
    },
    search: {
      type: String,
      default: ''
    },
    tbodyTrClass: {
      type: [String, Function],
      default: ''
    },
    height: {
      type: Number,
      default: 500
    },
    delayedCall: {
      type: Boolean,
      default: false
    },
    limit: {
      type: [Boolean, Number, String],
      default: false
    },
    page: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      dtKey: null,
      busy: false,
      currentPage: parseInt(this.page, 10) || 1,
      pageSize: parseInt(this.limit, 10) || 10,
      sort: null,
      stickyHeight: `${this.height}px`,
      isMounted: false,
      skipReload: false
    }
  },
  async fetch () {
    if (this.skipReload) {
      this.skipReload = false
      return
    }
    const query = {
      page: this.currentPage,
      limit: this.pageSize
    }
    if (this.useLimit) {
      query.limit = this.pageSize
    }
    if (this.sort) {
      query['order[by]'] = this.sort.by
      query['order[dir]'] = this.sort.dir
    } else if (this.order && this.order.by && this.order.dir) {
      this.sort = {
        by: this.order.by,
        dir: this.order.dir
      }
      query['order[by]'] = this.sort.by
      query['order[dir]'] = this.sort.dir
    }
    if (this.filter && this.filter.length) {
      for (const filter of this.filter) {
        if (typeof filter.value === 'object') {
          for (const key of Object.keys(filter.value)) {
            query[`filter[${filter.column}][${key}]`] = filter.value[key]
          }
        } else {
          query[`filter[${filter.column}]`] = filter.value
        }
      }
    }
    if (this.search && this.search !== '') {
      query.q = this.search
    }
    if (this.delayedCall) {
      this.addCall({
        repo: this.repo,
        method: 'index',
        parentId: this.parentId,
        query
      })
      if (this.isMounted) {
        this.$emit('on-call-added')
        this.updateSort()
      }
    } else {
      try {
        this.busy = true
        if (!isServer) {
          this.$nuxt.$loading.start()
        }
        await this.call({
          repo: this.repo,
          method: 'index',
          parentId: this.parentId,
          query
        })
        this.currentPage = parseInt(this.pager.current_page, 10)
      } finally {
        if (!isServer) {
          this.$nuxt.$loading.finish()
        }
        this.busy = false
        this.updateSort()
      }
    }
  },
  computed: {
    ...mapGetters({
      getByName: 'repos/getByName',
      deviceInfo: 'device'
    }),
    items () {
      return this.getByName(`${this.repo}/index`)
    },
    tableFields () {
      return this.fields.map((field) => {
        const newField = {}
        Object.keys(field).filter(k => k !== 'sortable').forEach((k) => {
          newField[k] = field[k]
        })
        return newField
      })
    },
    pager () {
      let pager = {
        count: 0,
        current_page: 0,
        links: [],
        per_page: 0,
        total: 0,
        total_pages: 0
      }
      if (this.items) {
        if (this.items?.meta?.pagination) {
          pager = this.items.meta.pagination
        } else {
          pager = {
            count: this.items.count,
            current_page: this.items.current_page,
            links: this.items.links,
            per_page: this.items.per_page,
            total: this.items.total,
            total_pages: this.items.total_pages
          }
        }
      }
      return pager
    },
    pagerPageSize () {
      return this.pager?.per_page || 10
    },
    locale () {
      return this.$i18n.locale
    },
    pageSizes () {
      const values = [
        {
          value: 10,
          text: 10
        },
        {
          value: 25,
          text: 25
        },
        {
          value: 50,
          text: 50
        },
        {
          value: 100,
          text: 100
        },
        {
          value: 200,
          text: 200
        },
        {
          value: 500,
          text: 500
        }
      ]
      const idx = values.findIndex((v) => {
        return v.value === this.pageSize
      })
      if (idx < 0) {
        values.push({
          value: this.pageSize,
          text: this.pageSize
        })
        values.sort((a, b) => {
          if (a.value < b.value) {
            return -1
          }
          if (a.value > b.value) {
            return 1
          }
          return 0
        })
      }
      return values
    },
    isMobile () {
      return this.deviceInfo?.type === 'mobile'
    }
  },
  watch: {
    page (n) {
      this.currentPage = n
    },
    limit (n) {
      this.pageSize = n
    },
    async currentPage (n) {
      if (n) {
        await this.$fetch()
        this.onPageChanged()
      }
    },
    pageSize (n) {
      if (n) {
        this.currentPage = 1
        this.$nextTick(async () => {
          await this.$fetch()
          this.onPageChanged()
        })
      }
    },
    pagerPageSize (n) {
      if (this.pageSize !== n) {
        // skip double load if server side page size changed
        this.skipReload = true
        this.pageSize = n
        this.$nextTick(() => {
          this.skipReload = false
        })
      }
    },
    filter: {
      handler () {
        this.$fetch()
      },
      deep: true
    },
    order: {
      async handler (v) {
        this.sort = {
          by: v.by,
          dir: v.dir
        }
        await this.$fetch()
        this.$emit('on-sort', this.sort)
      },
      deep: true
    },
    locale () {
      this.$fetch()
    },
    height () {
      this.calcHeight()
    }
  },
  mounted () {
    this.dtKey = (Math.random() + 1).toString(36).substring(8)
    if (this.fields) {
      for (const field of this.fields) {
        if (field && typeof field === 'object' && field.sortable) {
          const btn = document.createElement('span')
          btn.classList.add('sr-only')
          btn.dataset.column = field.key
          this.$refs[field.key].parentElement.append(btn)
          this.$refs[field.key].parentElement.ariaSort = 'none'
        }
      }
    }
    this.updateSort()
    this.calcHeight()
    this.isMounted = true
  },
  methods: {
    ...mapActions({
      call: 'repos/call',
      addCall: 'repos/addCall',
      flush: 'repos/flush'
    }),
    onUpdate () {
      this.$fetch()
      if (this.delayedCall) {
        this.$emit('on-call-added')
      }
    },
    async onSort (e, column) {
      const field = this.fields.find(i => i.key === column)
      if (field && field.sortable) {
        const cols = document.querySelectorAll(`#dt_${this.dtKey} [aria-sort]`)
        for (const col of cols) {
          col.ariaSort = 'none'
        }
        const dir = this.sort && this.sort.by === field.key
          ? (this.sort.dir.toLowerCase() === 'asc' ? 'desc' : 'asc')
          : 'asc'
        this.sort = {
          by: field.key,
          dir
        }
        await this.$fetch()
        this.$emit('on-sort', this.sort)
      }
    },
    updateSort () {
      this.$nextTick(() => {
        if (!isServer && this.sort) {
          const col = document.querySelector(`#dt_${this.dtKey} .${this.sort.by}`)
          if (col) {
            if (this.sort.dir === 'asc') {
              col.parentElement.ariaSort = 'ascending'
            } else if (this.sort.dir === 'desc') {
              col.parentElement.ariaSort = 'descending'
            } else {
              col.parentElement.ariaSort = 'none'
            }
          }
        }
      })
    },
    getById (id) {
      return this.items && this.items.data.find(i => i.id === id)
    },
    getMeta () {
      return this.items && this.items.meta
    },
    calcHeight () {
      if (this.$refs.wrapper && this.$refs?.pager && window && !this.isMobile) {
        const pagerHeight = this.$refs?.pager?.offsetHeight || this.$refs?.pager?.$el?.offsetHeight || 0
        this.stickyHeight = `${this.height - pagerHeight}px`
      } else {
        this.stickyHeight = `${this.height - 16}px`
      }
    },
    onPageChanged () {
      if (this.$refs.datatable) {
        this.$refs.datatable.$el.scrollTo({ top: 0 })
      }
    }
  }
}
</script>

<style lang="scss">

</style>
