<template>
  <b-form-select
    v-if="layout === 'input'"
    v-model="pageSize"
    :options="pageSizes"
    size="sm"
  />
  <b-dropdown-group
    v-else-if="layout === 'dropdown'"
    id="ddg-pagination"
    :header="$t('eDt_lt_pagesize')"
  >
    <b-dropdown-form>
      <b-form-group>
        <b-form-select
          v-model="pageSize"
          :options="pageSizes"
          size="sm"
        />
      </b-form-group>
    </b-dropdown-form>
  </b-dropdown-group>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PaginationPageSize',
  props: {
    value: {
      type: [Number, String],
      default: 10
    },
    layout: {
      type: [String],
      default: 'input'
    }
  },
  data () {
    return {
      pageSize: this.value
    }
  },
  computed: {
    ...mapGetters({
      deviceInfo: 'device'
    }),
    pageSizes () {
      const values = [
        {
          value: 10,
          text: 10
        },
        {
          value: 25,
          text: 25
        },
        {
          value: 50,
          text: 50
        },
        {
          value: 100,
          text: 100
        },
        {
          value: 200,
          text: 200
        },
        {
          value: 500,
          text: 500
        }
      ]
      const idx = values.findIndex((v) => {
        return v.value === this.pageSize
      })
      if (idx < 0) {
        values.push({
          value: this.pageSize,
          text: this.pageSize
        })
        values.sort((a, b) => {
          if (a.value < b.value) {
            return -1
          }
          if (a.value > b.value) {
            return 1
          }
          return 0
        })
      }
      return values
    },
    isMobile () {
      return this.deviceInfo?.type === 'mobile'
    }
  },
  watch: {
    value (n) {
      this.pageSize = parseInt(n, 10)
    },
    pageSize (n) {
      this.$emit('input', parseInt(n, 10))
    }
  }
}
</script>

<style scoped>

</style>
