<template>
  <div
    class="sort-wrapper d-inline-flex align-items-center text-nowrap small"
  >
    <span class="pr-1 pr-lg-2">{{ $t('eDt_sort') }}</span>
    <b-form-select
      v-model="sortValue"
      size="sm"
    >
      <b-form-select-option
        v-for="field in sortFields"
        :key="field.key"
        v-shtml="field.label"
        :value="field.key"
      />
    </b-form-select>
    <!--
    <client-only>
      <b-collapse
        :visible="state"
        @shown="onShown"
        @hidden="onHidden"
      >
        <b-card
          ref="sort"
          class="sort__container mb-2"
        >
          <div>
            <b-row>
              <b-col cols="12">
              </b-col>
            </b-row>
          </div>
          <template #header>
            <div class="d-inline-flex align-items-center justify-content-between w-100">
              <div>
                <font-awesome-icon
                  :icon="['fas', 'sort']"
                  class="mr-1"
                />
                {{ $t('eDoc_form_a_sort') }}
              </div>
              <b-link
                class="text-secondary"
                @click="() => { toggleState(); }"
              >
                <font-awesome-icon
                  :icon="['fas', 'xmark']"
                />
              </b-link>
            </div>
          </template>
          <template #footer>
            <b-row ref="footer">
              <b-col class="d-inline-flex justify-content-end">
                <b-btn class="ml-2" size="sm" @click="() => { onClear(); toggleState(); }">
                  {{ $t('eDt_btnClear') }}
                </b-btn>
              </b-col>
            </b-row>
          </template>
        </b-card>
      </b-collapse>
    </client-only>
    -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DataSort',
  props: {
    value: [Boolean],
    fields: {
      type: Array,
      default: () => ([])
    },
    order: {
      type: Object,
      default: () => ({})
    },
    def: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      state: this.value,
      sortBy: this.order?.by || '',
      sortDir: this.order?.dir || 'DESC',
      sortValue: this.order?.by ? `${this.order?.by}|${this.order?.dir}` : ''
    }
  },
  computed: {
    ...mapGetters({
      deviceInfo: 'device'
    }),
    directions: () => ([
      {
        key: 'asc',
        label: 'ASC'
      },
      {
        key: 'desc',
        label: 'DESC'
      }
    ]),
    isMobile () {
      return this.deviceInfo?.type === 'mobile'
    },
    sortFields () {
      const fields = []
      this.fields
        .filter(i => i.sortable)
        .forEach((f) => {
          this.directions
            .forEach((d) => {
              fields.push({
                key: `${f.key}|${d.key}`,
                label: `${f.label} - ${d.label}`
              })
            })
        })
      return fields
    }
  },
  watch: {
    value (n) {
      this.state = !!n
    },
    order: {
      handler (n) {
        if (this.sortValue !== `${n.by}|${n.dir}`) {
          this.sortValue = `${n.by}|${n.dir}`
        }
      },
      deep: true
    },
    sortValue () {
      this.onSort()
    }
  },
  methods: {
    toggleState () {
      this.state = !this.state
      this.$emit('input', this.state)
    },
    onClear () {
      if (typeof this.def.by !== 'undefined' && typeof this.def.dir !== 'undefined') {
        this.sortValue = `${this.def.by}|${this.def.dir}`
      }
    },
    onSort () {
      const values = this.sortValue.split('|')
      if (values.length === 2) {
        const by = values[0]
        const dir = values[1]
        this.$emit('on-sort', { by, dir })
      }
    },
    onShown (e) {
      this.$emit('on-shown', e)
    },
    onHidden (e) {
      this.$emit('on-hidden', e)
    }
  }
}
</script>

<style scoped>

</style>
