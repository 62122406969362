<template>
  <client-only>
    <div
      :id="`dt_${ukey}_pager`"
      ref="pager"
      class="pager d-inline-flex align-items-start justify-content-between"
    >
      <div
        v-if="isMobile && currentPage"
        class="d-inline-flex align-items-center text-nowrap small pr-1 pr-lg-2"
      >
        <span class="pr-1 pr-lg-2 pager__page_size_title">{{ $t('eDt_page') }}</span>
        <b-form-select
          v-model="currentPage"
          :options="pages"
          size="sm"
        />
      </div>
      <b-pagination
        v-else-if="currentPage"
        :key="currentPage"
        v-model="currentPage"
        :total-rows="pager && pager.total"
        :per-page="pager && pager.per_page"
        :aria-controls="`dt_${ukey}`"
        :disabled="busy"
        size="sm"
        :limit="7"
        @input="onPageChanged"
      />
      <div
        v-if="limit && !isMobile"
        class="d-inline-flex align-items-center text-nowrap small pr-1 pr-lg-2"
      >
        <span class="pr-1 pr-lg-2 pager__page_size_title ">{{ $t('eDt_pageSize') }}</span>
        <b-form-select
          v-model="pageSize"
          :options="pageSizes"
          size="sm"
        />
      </div>
      <strong v-if="pager" class="pager__info small">
        {{ $t('eDt_iteratorCounts', {
          from: ((pager.current_page - 1) * pager.per_page) + 1,
          to: (pager.current_page) * pager.per_page,
          total: pager.total
        }) }}
      </strong>
    </div>
  </client-only>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Pagination',
  props: {
    ukey: {
      type: String,
      default: ''
    },
    busy: {
      type: Boolean,
      default: false
    },
    limit: {
      type: [Boolean, Number, String],
      default: false
    },
    items: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      currentPage: 1,
      pageSize: parseInt(this.limit, 10) || 10
    }
  },
  computed: {
    ...mapGetters({
      deviceInfo: 'device'
    }),
    pager () {
      let pager = {
        count: 0,
        current_page: 0,
        links: [],
        per_page: 0,
        total: 0,
        total_pages: 0
      }
      if (this.items) {
        if (this.items?.meta?.pagination) {
          pager = this.items.meta.pagination
        } else {
          pager = {
            count: this.items.count,
            current_page: this.items.current_page,
            links: this.items.links,
            per_page: this.items.per_page,
            total: this.items.total,
            total_pages: this.items.total_pages
          }
        }
      }
      return pager
    },
    pageSizes () {
      const values = [
        {
          value: 10,
          text: 10
        },
        {
          value: 25,
          text: 25
        },
        {
          value: 50,
          text: 50
        },
        {
          value: 100,
          text: 100
        },
        {
          value: 200,
          text: 200
        },
        {
          value: 500,
          text: 500
        }
      ]
      const idx = values.findIndex((v) => {
        return v.value === this.pageSize
      })
      if (idx < 0) {
        values.push({
          value: this.pageSize,
          text: this.pageSize
        })
        values.sort((a, b) => {
          if (a.value < b.value) {
            return -1
          }
          if (a.value > b.value) {
            return 1
          }
          return 0
        })
      }
      return values
    },
    pages () {
      const bound = 3
      const totalPages = this.pager.total_pages
      function getNext (p) {
        const values = []
        if (p + bound <= totalPages) {
          for (let i = p + 1; i <= p + bound; i++) {
            values.push({
              value: i,
              text: i
            })
          }
        } else {
          for (let i = p + 1; i <= totalPages; i++) {
            values.push({
              value: i,
              text: i
            })
          }
        }
        return values
      }
      function getPrev (p) {
        let i = p > bound ? p - bound : 1
        const j = p > bound ? p - 1 : p
        const values = []
        for (i; i <= j; i++) {
          values.push({
            value: i,
            text: i
          })
        }
        return values
      }
      function getNextMedium (p) {
        const values = []
        if (totalPages - p - 1 > bound) {
          const i = p + Math.ceil((totalPages - p) / 2) + 1
          values.push({
            value: i,
            text: '...'
          })
        }
        return values
      }
      function getPrevMedium (p) {
        const values = []
        if (p - bound > 1) {
          const i = Math.floor(p / 2) - 1
          if (i > 1) {
            values.push({
              value: i,
              text: '...'
            })
          }
        }
        return values
      }
      let values = []
      values.push(
        {
          value: 1,
          text: 1
        }
      )
      if (this.currentPage === 1) {
        values = values.concat(...getNext(this.currentPage))
        values = values.concat(...getNextMedium(this.currentPage))
      } else if (this.currentPage === this.pager.total_pages) {
        values = values.concat(...getPrevMedium(this.currentPage))
        values = values.concat(...getPrev(this.currentPage))
      } else {
        values = values.concat(...getPrevMedium(this.currentPage))
        values = values.concat(...getPrev(this.currentPage))
        values.push(
          {
            value: this.currentPage,
            text: this.currentPage
          }
        )
        values = values.concat(...getNext(this.currentPage))
        values = values.concat(...getNextMedium(this.currentPage))
      }
      values.push(
        {
          value: this.pager.total_pages,
          text: this.pager.total_pages
        }
      )
      values = [...new Map(values.map(item => [item.value, item])).values()]
      return values
    },
    isMobile () {
      return this.deviceInfo?.type === 'mobile'
    }
  },
  watch: {
    pager: {
      handler (n) {
        if (n?.current_page !== this.currentPage) {
          this.currentPage = parseInt(n.current_page, 10)
        }
        if (n?.per_page !== this.pageSize) {
          this.pageSize = parseInt(n.per_page, 10)
        }
      },
      deep: true
    },
    currentPage (n) {
      if (n) {
        this.onPageChanged()
      }
    },
    pageSize (n) {
      if (n) {
        this.onSizeChanged()
      }
    }
  },
  methods: {
    onPageChanged () {
      this.$emit('page-changed', this.currentPage)
      this.$forceUpdate()
    },
    onSizeChanged () {
      this.$emit('size-changed', this.pageSize)
    }
  }
}
</script>

<style scoped>

</style>
